import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router'
import { KeycloakAuthGuard, KeycloakEventType, KeycloakService } from 'keycloak-angular'
import { Subject } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class AuthenticationService extends KeycloakAuthGuard {
   private readonly _cn = this.constructor.name

   licencesSubject$: Subject<string[]> = new Subject<string[]>()

   constructor(
      protected override router: Router,
      public override keycloakAngular: KeycloakService
   ) {
      super(router, keycloakAngular)

      keycloakAngular.keycloakEvents$.subscribe({
         next: (e) => {
            switch (e.type) {
               case KeycloakEventType.OnTokenExpired:
                  this.keycloakEventLog(e.type)
                  keycloakAngular.updateToken(5)
                  break
               case KeycloakEventType.OnAuthSuccess:
                  this.keycloakEventLog(e.type)
                  break
               case KeycloakEventType.OnAuthRefreshSuccess:
                  this.keycloakEventLog(e.type)
                  this.licencesSubject$.next(this.getUserLicences())
                  break
               case KeycloakEventType.OnAuthRefreshError:
                  this.keycloakEventLog(e.type)
                  keycloakAngular.login()
                  break
               default:
                  break
            }
         },
      })
   }

   getUserLicences(): string[] {
      return this.keycloakAngular.getUserRoles(true)
   }

   ngOnDestroy(): void {
      this.keycloakAngular.keycloakEvents$.unsubscribe()
   }

   async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
      return new Promise((resolve, reject) => {
         if (!this.authenticated) {
            this.keycloakAngular.login().catch((e) => console.error(e))
            reject(false)
         }
         resolve(true)
      })
   }

   logout() {
      this.keycloakAngular.logout()
   }

   private keycloakEventLog(type: KeycloakEventType) {
      console.log(this._cn, 'Keycloak event - Type: ' + KeycloakEventType[type])
   }
}
